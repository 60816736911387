import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import GetQuote from 'src/components/pages/getQuote/GetQuote';
import AboutUs from 'src/components/pages/aboutUs/AboutUs';
import PrivacyPolicy from 'src/components/pages/privacyPolicy/PrivacyPolicy';
import TermsAndConditions from 'src/components/pages/termsAndConditions/TermsAndConditions';
import ArtworkRequirements from 'src/components/pages/artworkRequirements/ArtworkRequirements';
import HowWeHelp from 'src/components/pages/howWeHelp/HowWeHelp';
import Capabilities from 'src/components/pages/capabilities/Capabilities';
//import News from 'src/components/pages/news/News';
import Faq from 'src/components/pages/faq/Faq';
import BlogDetail from 'src/components/apps/blog/detail/BlogDetail';
import ThankYou from 'src/components/pages/thankyou/thankyou';
import OurWorks2 from 'src/views/pages/ourworks2/OurWorks2';

const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));
const Landingpage = Loadable(lazy(() => import('../views/pages/landingpage/Landingpage')));
const Ecommerce = Loadable(lazy(() => import('../views/pages/eCommerce/Ecommerce')));
const ProductDetailLayout = Loadable(lazy(() => import('src/components/apps/ecommerce/productDetail/ProductDetailLayout')),);
const EcomProductCheckout = Loadable(lazy(() => import('../views/pages/eCommerce/EcommerceCheckout')), { ssr: false });
const ContactUs = Loadable(lazy(() => import('../components/pages/contactUs/ContactUs')));
const UnderConstruction = Loadable(lazy(() => import('../views/UnderConstruction')));

const Router = [
  {
    path: '/',
    element: <BlankLayout />,
    children: [
      { path: '/', element: <Landingpage /> },
      { path: '/Our-works', element: <OurWorks2 /> },
      { path: '/Products', element: <Ecommerce /> },
      { path: '/apps/ecommerce/eco-checkout', element: <EcomProductCheckout /> },
      { path: '/Products/:id', element: <ProductDetailLayout /> },
      { path: '/About-us', element: <AboutUs /> },
      { path: '/How-we-help', element: <HowWeHelp /> },
      { path: '/Capabilities', element: <Capabilities /> },
      { path: '/Contact-us', element: <ContactUs /> },
      { path: '/Get-quote', element: <GetQuote /> },
      /* { path: '/Learn/News', element: <News /> }, */
      { path: '/Learn/News', element: <Navigate to="/404" /> },
      { path: '/Learn/:id', element: <BlogDetail /> },
      { path: '/Learn/Frequently-asked-questions', element: <Faq /> },
      { path: '/Learn/Artwork-requirements', element: <ArtworkRequirements /> },
      { path: '/Privacy-policy', element: <PrivacyPolicy /> },
      { path: '/Terms-and-conditions', element: <TermsAndConditions /> },
      { path: '/Thank-you', element: <ThankYou /> },
      { path: '/404', element: <UnderConstruction /> },
      { path: '*', element: <Navigate to="/404" /> },
    ],
  },
];


export default Router;
