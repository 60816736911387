/* eslint-disable no-useless-escape */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-sequences */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-cond-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable new-parens */
/* eslint-disable no-useless-concat */
import React, { useState } from 'react';
import { Box, Stepper, Step, StepLabel, Button, Typography, Grid } from '@mui/material';
import PageContainer from 'src/components/container/PageContainer';
import ParentCard from '../../shared/ParentCard';
import CustomTextField from '../../forms/theme-elements/CustomTextField';
import CustomFormLabel from '../../forms/theme-elements/CustomFormLabel';
import BlankCard from 'src/components/shared/BlankCard';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const StyledButton = styled(Button)(() => ({
  padding: '6px 16px',
  fontSize: '14px',
}));

const steps = ['Product Details', 'Contact Details', 'Finish'];

const QuoteFormWizard = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    productName: '',
    productDesc: '',
    prodMaterial: '',
    prodDimensions: '',
    colorNo: '',
    prodQty: '',
    addnNotes: '',
    fName: '',
    lName: '',
    email: '',
    phone: '',
    company: '',
    address: '',
    uploadedFiles: [],
  });

  const [errors, setErrors] = useState({});
  // eslint-disable-next-line
  const [emailStatus, setEmailStatus] = useState('');

  const handleFileUpload = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return;
    }

    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    const maxFileSize = 128 * 1024 * 1024;
    const maxFiles = 5;

    const validFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (!allowedTypes.includes(file.type)) {
        console.warn(`File "${file.name}" has an invalid file type.`);
        continue;
      }

      if (file.size > maxFileSize) {
        console.warn(`File "${file.name}" exceeds the maximum file size.`);
        continue;
      }

      validFiles.push(file);
      if (validFiles.length === maxFiles) {
        console.warn('Maximum number of files reached.');
        break;
      }
    }

    setUploadedFiles((prevFiles) => [...prevFiles, ...validFiles]);
    setFormData((prevData) => ({
      ...prevData,
      uploadedFiles: [...prevData.uploadedFiles, ...validFiles],
    }));
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
    setFormData((prevData) => ({
      ...prevData,
      uploadedFiles: updatedFiles,
    }));
  };

  const validateEmail = (email) => {
    // Simple email validation regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  /* const validatePhoneNumber = (number) => {
    // Simple phone number validation regex for 13 digits
    const regex = /^\d{13}$/;
    return regex.test(number);
  }; */

  const validateText = (name) => {
    // Simple name validation regex to allow only letters and spaces
    const regex = /^[A-Za-z\s]+$/;
    return regex.test(name);
  };
   // eslint-disable-next-line
  const validateNumber = (number) => {
    // Simple number validation regex to allow only digits
    const regex = /^\d+$/;
    return regex.test(number);
  };

  const handleNext = () => {
    const requiredFields =
      activeStep === 0
        ? [
            'productName',
            'productDesc',
            'prodMaterial',
            'prodDimensions',
            'colorNo',
            'prodQty',
            'addnNotes',
          ]
        : ['fName', 'lName', 'email', 'phone'];

    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) newErrors[field] = 'This field is required';
    });

    if (!validateEmail(formData['email']) && formData['email'] != '') {
      newErrors['email'] = 'Email is not valid';
    }

    /* if (!validatePhoneNumber(formData['phone']) && formData['phone'] != '') {
      newErrors['phone'] = 'Please enter correct phone number format';
    } */

    if (!validateText(formData['fName']) && formData['fName'] != '') {
      newErrors['fName'] = 'Enter text only';
    }

    if (!validateText(formData['lName']) && formData['lName'] != '') {
      newErrors['lName'] = 'Enter text only';
    }

    /* if (!validateNumber(formData['prodQty']) && formData['prodQty'] != '') {
      newErrors['prodQty'] = 'Enter number only';
    } */

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setErrors({});
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setErrors({});
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    var myForm = new FormData();

    // // Append all form data fields to FormData
    Object.keys(formData).forEach((key, value) => {
      if (key === 'uploadedFiles') {
        formData[key].forEach((file, index) => {
          myForm.append(`uploadedFiles[${index}]`, file);
        });
      } else {
        myForm.append(key, formData[`${key}`]);
      }
    });

    const xhr = new XMLHttpRequest();
    //xhr.open('POST', 'https://qspgroup.designsdusted.com/includes/sendquote.php', true);
    xhr.open('POST', 'https://qspgroup.com.au/includes/sendquote.php', true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          try {
            const response = JSON.parse(xhr.responseText);
            if (response.status === 'success') {
              toast('Quote request submitted successfully.');
              setIsSubmitting(false);
              setFormData({
                productName: '',
                productDesc: '',
                prodMaterial: '',
                prodDimensions: '',
                colorNo: '',
                prodQty: '',
                addnNotes: '',
                fName: '',
                lName: '',
                email: '',
                phone: '',
                company: '',
                address: '',
                uploadedFiles: [],
                // Reset other form fields as needed
              });
              window.location.href = '/Thank-you';
            } else {
              toast('Error submitting form.');
              setIsSubmitting(false);
            }
            setEmailStatus(response.message);
          } catch (e) {
            setEmailStatus(xhr.responseText); // Handle non-JSON response
          }
        } else {
          setEmailStatus('Error submitting form: ' + xhr.statusText);
          toast('Error submitting form.');
        }
      }
    };
    // eslint-disable-next-line
    const dataToSend = {
      ...formData,
    };
    // eslint-disable-next-line
    const response = xhr.send(myForm);

    // {emailStatus && <Alert severity="info">{emailStatus}</Alert>}

    //{emailStatus && <Alert severity="info">{emailStatus}</Alert>}
  };

  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <div className="formBlock">
            <form onSubmit={handleNext}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="productName">Product Name</CustomFormLabel>
                  <CustomTextField
                    name="productName"
                    id="productName"
                    variant="outlined"
                    placeholder="What product or service would you like a quote on?"
                    fullWidth
                    value={formData.productName}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.productName}
                    helperText={errors.productName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="productDesc">Product(s) Description</CustomFormLabel>
                  <CustomTextField
                    name="productDesc"
                    id="productDesc"
                    variant="outlined"
                    placeholder="Enter description of your product(s)"
                    fullWidth
                    multiline
                    rows={4}
                    value={formData.productDesc}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.productDesc}
                    helperText={errors.productDesc}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="prodMaterial">Material</CustomFormLabel>
                  <CustomTextField
                    name="prodMaterial"
                    id="prodMaterial"
                    variant="outlined"
                    placeholder="Specify the material of the product(s)"
                    fullWidth
                    value={formData.prodMaterial}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.prodMaterial}
                    helperText={errors.prodMaterial}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="prodDimensions">Dimensions</CustomFormLabel>
                  <CustomTextField
                    name="prodDimensions"
                    id="prodDimensions"
                    variant="outlined"
                    placeholder="Provide the dimensions of the product(s) in mm or cm"
                    fullWidth
                    value={formData.prodDimensions}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.prodDimensions}
                    helperText={errors.prodDimensions}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="colorNo">Number of colours</CustomFormLabel>
                  <CustomTextField
                    name="colorNo"
                    id="colorNo"
                    variant="outlined"
                    placeholder="Specify the number of colours"
                    fullWidth
                    value={formData.colorNo}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.colorNo}
                    helperText={errors.colorNo}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="prodQty">Quantity</CustomFormLabel>
                  <CustomTextField
                    name="prodQty"
                    id="prodQty"
                    variant="outlined"
                    placeholder="Specify quantity required"
                    fullWidth
                    value={formData.prodQty}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.prodQty}
                    helperText={errors.prodQty}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="addnNotes">Additional Notes</CustomFormLabel>
                  <CustomTextField
                    name="addnNotes"
                    id="addnNotes"
                    variant="outlined"
                    placeholder="Eg. Holes, rounded corners, numbering, diecut to shape etc..."
                    fullWidth
                    multiline
                    rows={4}
                    value={formData.addnNotes}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <BlankCard>
                    <CardContent
                      sx={{
                        border: '1px dashed #e5eaef',
                        borderRadius: '8px',
                        background: '#f3f3f3',
                      }}
                    >
                      <Box textAlign="center">
                        {uploadedFiles.map((file, index) => (
                          <Box key={index} display="flex" alignItems="center" my={2}>
                            <Typography variant="body1" sx={{ mr: 1 }}>
                              {file.name}
                            </Typography>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleRemoveFile(index)}
                            >
                              Remove
                            </Button>
                          </Box>
                        ))}
                        <Button variant="contained" component="label" sx={{ mt: 1 }}>
                          Upload File
                          <input type="file" hidden onChange={handleFileUpload} multiple />
                        </Button>
                        <Typography variant="body1" sx={{ mt: 1, color: 'grey' }}>
                          Accepted file types: jpg, png, pdf, Max. file size: 128 MB, Max. files: 5.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1, color: 'grey' }}>
                          By including the design, this will help with quoting the print job.
                        </Typography>
                      </Box>
                    </CardContent>
                  </BlankCard>
                </Grid>
              </Grid>
            </form>
          </div>
        );
      case 1:
        return (
          <div className="formBlock">
            <form onSubmit={handleNext}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="fName">First Name</CustomFormLabel>
                  <CustomTextField
                    name="fName"
                    id="fName"
                    variant="outlined"
                    placeholder="Enter your first name"
                    fullWidth
                    value={formData.fName}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.fName}
                    helperText={errors.fName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="lName">Last Name</CustomFormLabel>
                  <CustomTextField
                    name="lName"
                    id="lName"
                    variant="outlined"
                    placeholder="Enter your last name"
                    fullWidth
                    value={formData.lName}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.lName}
                    helperText={errors.lName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="email">Email Address</CustomFormLabel>
                  <CustomTextField
                    name="email"
                    id="email"
                    type="email"
                    variant="outlined"
                    placeholder="Enter your email"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="phone">Phone Number</CustomFormLabel>
                  <CustomTextField
                    name="phone"
                    id="phone"
                    variant="outlined"
                    placeholder="Enter your phone number"
                    fullWidth
                    value={formData.phone}
                    onChange={handleChange}
                    autoComplete="off"
                    error={!!errors.phone}
                    helperText={errors.phone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="company">Company</CustomFormLabel>
                  <CustomTextField
                    name="company"
                    id="company"
                    variant="outlined"
                    placeholder="Enter your company name"
                    fullWidth
                    value={formData.company}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomFormLabel htmlFor="address">Address</CustomFormLabel>
                  <CustomTextField
                    name="address"
                    id="address"
                    variant="outlined"
                    placeholder="Enter your address"
                    fullWidth
                    multiline
                    rows={4}
                    value={formData.address}
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </Grid>
              </Grid>
            </form>
          </div>
        );
      case 2:
        return (
          <div className="formBlock">
            <Typography variant="h5" align="center" gutterBottom>
              Review your details
            </Typography>
            <Typography variant="body1" align="center" paragraph>
              Please review the information you have provided. If everything is correct, click
              "Submit" to send your quote request.
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box sx={{ mt: 2, mb: 1 }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <PageContainer>
      <ParentCard title="Request a Quote">
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 2, mb: 1 }}>
            <div>{handleSteps(activeStep)}</div>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <StyledButton
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </StyledButton>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep < steps.length - 1 && (
              <StyledButton onClick={handleNext} sx={{ mr: 1 }}>
                Next
              </StyledButton>
            )}
          </Box>
        </Box>
      </ParentCard>
      <ToastContainer />
    </PageContainer>
  );
};

export default QuoteFormWizard;
